import logo from './logo.svg';
import './App.css';
import { Container, Typography, Grid, ThemeProvider, useTheme, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, IconButton, Box } from '@mui/material';


// Header component
const Header = () => {
  return (
    <header>
      <h1>wikiactions.org</h1>
      <p>Invigorating collaboration. Overcoming self-limiting social norms and naysayers. Unleashing human potential. </p>
    </header>
  );
};

// Features component
const Features = () => {
  return (
    <section className="features">
      <div className="feature">
        <h2>Speak Up Freely</h2>
        <p> No risk of being judged or mislabeled. Prioritize your privacy. Initiatives (Ideas) are weighed by merits rather than from whom. </p>
      </div>
      <div className="feature">
        <h2>Leverage Ideas and Expertise Across Silos Any Time Any Where</h2>
        <p>24 x 7 virtual brainstorming that can be shared outside your existing network to get more diverse ideas and faster resolution. Great for people with a growth mindset.</p>
      </div>
      <div className="feature">
        <h2>Earn Recognitions</h2>
        <p>Depending on how active you are and how impactful your contribution is to the community, you could earn recognitions that can open more doors for your career.</p>
      </div>
    </section>
  );
};

// Call To Action (CTA) component
// const CTA = () => {
//   return (
//     <section className="cta">
//       <h2>Ready to get started?</h2>
//       <p>Sign up now as a beta user!</p>
//       <button>Sign Up</button>
//     </section>
//   );
// };

const CTA = () => {
  return (
    <Typography
      variant="body1"
      sx={{ color: '#61dafb' }}
      component="a"
      href="https://forms.gle/92ycEFb94KcKsvAn6"
    >
      Sign up as a Beta User
    </Typography>
  );
};





function App() {
  return (
    <div className='App'>
      <div className='App-header'>
        <Container>
          <Header />
          <Features />
          <CTA />
        </Container>
      </div>
    </div>
  );
}

export default App;
